import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Container, Table } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import AusweisService from "../services/ausweis.service";

export default class BoardOfficeAusweis extends Component {
  constructor(props) {
    super();
    this.state = {
      wachgaenger: []
    };

    library.add(faDownload);
  }

  componentDidMount() {
    AusweisService.getWachgaenger().then(
      response => {
        this.handleWachgaenger(response.data);
      },
      error => {
      }
    );
  }

  handleWachgaenger(wachgaenger) {
    this.setState({
      wachgaenger: wachgaenger
    })
  }

  render() {
    return (
      <Container>
        <h2>Wach- und Fährausweis</h2>
        {this.state.wachgaenger.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Wachgänger</th><th>Download Ausweise</th>
              </tr>
            </thead>
            <tbody>
              {this.state.wachgaenger.map((cert, key) => {
                return <tr><td>{cert.nachname}, {cert.vorname}</td><td><FontAwesomeIcon icon='download' onClick={() => AusweisService.getWachschild(cert.id, cert.nachname + cert.vorname)} /></td></tr>
              })}
            </tbody>
          </Table>
        )}
      </Container>
    );
  }
}
