import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Button from "react-bootstrap/Button";
import { LinkContainer } from 'react-router-bootstrap';
import { Nav } from "react-bootstrap";


import AuthService from "../services/auth.service";
import { Card } from "react-bootstrap";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Füllen Sie dieses Feld aus!
      </div>
    );
  }
};

export default class BoardLogin extends Component {
  constructor(props) {
    super();
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: ""
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.login(this.state.email, this.state.password).then(
        () => {
          window.location = '/profile';
        },
        error => {
          let resMessage;
          if(error.response.status === 401) {
            resMessage = "E-Mail oder Passwort falsch."
          } else {
            resMessage = "Interner Fehler."
          }

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <Card style={{ maxWidth: '25rem' }}>
        <Card.Header>
          Anmelden
        </Card.Header>
        <Card.Body>
          <Form
            onSubmit={this.handleLogin}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="email">E-Mail-Adresse</label>
              <Input
                type="email"
                className="form-control"
                name="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Passwort</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
            </div>
            <br />
            <div className="form-group">
              <Button
                block
                as="input"
                type="submit"
                disabled={this.state.loading}
                value="Login">
              </Button>
            </div>

            <LinkContainer to="/resetpassword">
                  <Nav.Link>Passwort vergessen?</Nav.Link>
            </LinkContainer>

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </Card.Body>
      </Card>
    );
  }
}
