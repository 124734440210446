import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from '../const';

const API_URL = baseUrl + 'station/';

class StationService {
    getSaison() {
        return axios.get(API_URL, {
            headers: authHeader()
        });
    }

}

export default new StationService();