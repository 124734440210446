import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Container, Table } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import TravelexpensesService from "../services/travelexpenses.service";

export default class BoardTravelexpensesComponent extends Component {
  constructor(props) {
    super();
    this.state = {
        travelExpenses: []
    };
    this.weekday = ["Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag"];

    library.add(faDownload);
  }

  componentDidMount() {
    TravelexpensesService.getTravelexpenses().then(
      response => {
        this.handleTravelexpenses(response.data);
      },
      error => {
      }
    );
  }

  handleTravelexpenses(travelExpenses) {
    this.setState({
        travelExpenses
    })
  }

  render() {
    return (
      <Container>
        <h2>Fahrtkostenerstattung</h2>
        {this.state.travelExpenses.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Bearbeitungsdatum</th><th>Download PDF</th><th>Download SEPA-Datei</th>
              </tr>
            </thead>
            <tbody>
              {this.state.travelExpenses.map(value => {
                let d = new Date(value);
                return <tr><td>{this.weekday[d.getDay()]}, {(('0' + d.getDate()).slice(-2)) + "." + (('0' + (d.getMonth() + 1)).slice(-2)) + "." + d.getFullYear()}</td><td><FontAwesomeIcon icon='download' onClick={() => TravelexpensesService.getAbrechnungPdf(value)} /></td><td><FontAwesomeIcon icon='download' onClick={() => TravelexpensesService.getAbrechnungSepa(value)} /></td></tr>
              })}
            </tbody>
          </Table>
        )}
      </Container>
    );
  }
}
