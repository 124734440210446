import React, { Component } from "react";
import { Alert, Card, Col, Container, Row, Form, Button } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import PersonService from "../services/person-service";
import AsyncSelect from "react-select/async";
import { isMacOs, isSafari } from "react-device-detect";
import DatePicker from "react-date-picker";
import { isValidBIC, isValidIBAN } from "ibantools";

export default class BoardProfile extends Component {
  constructor(props) {
    super();
    this.handlePersonData = this.handlePersonData.bind(this);
    this.handlePersonSubmit = this.handlePersonSubmit.bind(this);
    this.onChangeBic = this.onChangeBic.bind(this);
    this.onChangeIban = this.onChangeIban.bind(this);
    this.onChangeKontoinhaber = this.onChangeKontoinhaber.bind(this);
    this.onChangeGliederung = this.onChangeGliederung.bind(this);
    this.onChangeNotfallkontakt = this.onChangeNotfallkontakt.bind(this);
    this.onChangeTelefonnummer = this.onChangeTelefonnummer.bind(this);
    this.onChangeStrasse = this.onChangeStrasse.bind(this);
    this.onChangePlz = this.onChangePlz.bind(this);
    this.onChangeOrt = this.onChangeOrt.bind(this);
    this.onChangeGeburtsdatum = this.onChangeGeburtsdatum.bind(this);
    this.onChangeNachname = this.onChangeNachname.bind(this);
    this.onChangeVorname = this.onChangeVorname.bind(this);
    this.onChangePasswort = this.onChangePasswort.bind(this);
    this.onChangePasswortRepeat = this.onChangePasswortRepeat.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);

    this.state = {
      redirect: null,
      userReady: false,
      profile: null,
      isLoading: false,
      passwordsMatch: false,
      message: "",
      validated: false,
    };
  }

  componentDidMount() {
    PersonService.getPerson(null, this.handlePersonData);

    // if (!this.state.profile) this.setState({ redirect: "/" });
    // this.setState({ userReady: true })
  }

  handlePersonData(profile) {
    let d = new Date(profile.geburtsdatum);
    profile.geburtsdatum = d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2));
    this.setState({ profile, userReady: true });
  }

  handlePersonSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true
    });


    PersonService.putPerson(this.state.profile).then(
      () => {
        let message = "Deine Angaben wurden gespeichert!"
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        this.setState({
          message,
          loading: false,
        });
      }
    );

  }

  onChangeKontoinhaber(e) {
    let profile = this.state.profile;
    profile.kontoinhaber = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeIban(e) {
    let profile = this.state.profile;
    profile.iban = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeBic(e) {
    let profile = this.state.profile;
    profile.bic = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeGliederung(e) {
    let profile = this.state.profile;
    profile.gliederung = e.value;
    this.setState({
      profile
    });
  }

  onChangeNotfallkontakt(e) {
    let profile = this.state.profile;
    profile.notfallkontakt = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeTelefonnummer(e) {
    let profile = this.state.profile;
    profile.telefonnummer = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeStrasse(e) {
    let profile = this.state.profile;
    profile.strasse = e.target.value;
    this.setState({
      profile
    });
  }

  onChangePlz(e) {
    let profile = this.state.profile;
    profile.plz = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeOrt(e) {
    let profile = this.state.profile;
    profile.ort = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeGeburtsdatum(e) {
    let profile = this.state.profile;
    profile.geburtsdatum = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeNachname(e) {
    let profile = this.state.profile;
    profile.nachname = e.target.value;
    this.setState({
      profile
    });
  }

  onChangeVorname(e) {
    let profile = this.state.profile;
    profile.vorname = e.target.value;
    this.setState({
      profile
    });
  }

  onChangePasswort(e) {
    let profile = this.state.profile;
    profile.account.passwort = e.target.value;
    this.setState({
      profile,
      passwordChanged: true,
    });
  }

  onChangePasswortRepeat(e) {
    this.setState({
      repeatedPassword: e.target.value,
      passwordsMatch: e.target.value === this.state.profile.account.passwort
    });
  }

  onChangeEmail(e) {
    let profile = this.state.profile;
    profile.email = e.target.value;
    this.setState({
      profile
    });
  }

  isValidEmail(e) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(e).toLowerCase());
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />
    }

    const { profile, repeatedPassword } = this.state;

    return (
      <Container>
        {(this.state.userReady) ?
          <div>
            <h3>
              Persönliche Angaben
            </h3>
            {this.state.message && (
              <Alert variant={'success'}>{this.state.message}</Alert>
            )}
            <Form
              onSubmit={this.handlePersonSubmit}
              ref={c => {
                this.form = c;
              }}
              validated={this.state.validated}
            >
              <Card>
                <Card.Header>
                  <Card.Title>
                    Personendaten
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col>Vorname | Name</Col>
                    <Col><Form.Control value={profile.vorname} onChange={this.onChangeVorname} /></Col><Col><Form.Control rows={5} value={profile.nachname} onChange={this.onChangeNachname} /></Col>
                  </Row>
                  <Row>
                    <Col xs={4}>Geburtsdatum</Col>
                    <Col xs={8}>
                      {(isMacOs && isSafari) ? (
                        <DatePicker
                          maxDate={new Date("2007-05-15")}
                          onChange={(d) => this.onChangeGeburtsdatum({ target: { value: d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2)) } })}
                          required={true}
                          value={new Date(profile.geburtsdatum)}
                          format="dd.MM.y"
                        />
                      ) : (
                        <Form.Control type="date" max="2007-05-15" required={true} value={profile.geburtsdatum} onChange={this.onChangeGeburtsdatum} />
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}><Button variant="primary" type="submit" disabled={this.state.loading}>Grunddaten ändern</Button></Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Account
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={4}>E-Mail-Adresse</Col>
                    <Col xs={8}>
                      <Form.Control type="email" required={true} isInvalid={!this.isValidEmail(profile.email)} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" value={profile.email} onChange={this.onChangeEmail} />
                      <Form.Control.Feedback type="invalid">
                        Die E-Mail-Adresse ist ungültig.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}>Neues Passwort</Col>
                    <Col xs={8}><Form.Control type={"password"} pattern="^$|.{8,}" title={"Das Passwort muss mindestens 8 Zeichen haben."} placeholder={"********"} value={profile.account.passwort} onChange={this.onChangePasswort} /></Col>
                  </Row>
                  <Row hidden={profile.account == null || profile.account.passwort == null || profile.account.passwort.length < 0}>
                    <Col xs={4}>Passwort wiederholen</Col>
                    <Col xs={8}><Form.Control type={"password"} pattern={profile.account.passwort ? profile.account.passwort.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : ""} title={"Die Passwörter stimmen nicht überein."} required={!(profile.account == null || profile.account.passwort == null || profile.account.passwort.length < 0)} value={repeatedPassword} onChange={this.onChangePasswortRepeat} /></Col>
                  </Row>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}><Button variant="primary" type="submit" disabled={this.state.loading}>Account ändern</Button></Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Adressdaten
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={4}>Straße</Col>
                    <Col xs={8}><Form.Control value={profile.strasse} onChange={this.onChangeStrasse} /></Col>
                  </Row>
                  <Row>
                    <Col>PLZ | Ort</Col>
                    <Col><Form.Control value={profile.plz} onChange={this.onChangePlz} /></Col><Col><Form.Control value={profile.ort} onChange={this.onChangeOrt} /></Col>
                  </Row>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}><Button variant="primary" type="submit" disabled={this.state.loading}>Adresse ändern</Button></Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Kontaktdaten
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={4}>Telefonnummer</Col>
                    <Col xs={8}><Form.Control required={true} value={profile.telefonnummer} onChange={this.onChangeTelefonnummer} /></Col>
                  </Row>
                  <Row>
                    <Col xs={4}>Notfallkontakt</Col>
                    <Col xs={8}><Form.Control required={true} value={profile.notfallkontakt} onChange={this.onChangeNotfallkontakt} /></Col>
                  </Row>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}><Button variant="primary" type="submit" disabled={this.state.loading}>Kontaktdaten ändern</Button></Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>
                    DLRG Mitgliedschaft
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col xs={4}>Ortsgruppe/Gliederung:</Col>
                    <Col xs={8}>
                      <AsyncSelect
                        isSearchable={true}
                        loadOptions={PersonService.getGliederung}
                        defaultValue={{ value: profile.gliederung, label: profile.gliederung }}
                        onChange={this.onChangeGliederung}
                      >
                      </AsyncSelect>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}><Button variant="primary" type="submit" disabled={this.state.loading}>DLRG-Mitgliedschaft ändern</Button></Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <Card.Title>
                    Bankverbindung
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  Die Bankverbindung benötigen wir für die Überweisung des Wachgeldes und zur Erstattung der Reisekosten.
                  <Row>
                    <Col xs={4}>Kontoinhaber:</Col>
                    <Col xs={8}><Form.Control required={true} value={profile.kontoinhaber} onChange={this.onChangeKontoinhaber} /></Col>
                  </Row>
                  <Form.Group as={Row} controlId="validationIban">
                    <Form.Label as={Col} xs={4}>IBAN:</Form.Label>
                    <Col xs={8}>
                      <Form.Control required={true} isInvalid={!isValidIBAN(profile.iban)} value={profile.iban} onChange={this.onChangeIban} />
                      <Form.Control.Feedback type="invalid">
                        IBAN ist ungültig.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="validationBic">
                    <Form.Label as={Col} xs={4}>BIC:</Form.Label>
                    <Col><Form.Control required={true} isInvalid={!isValidBIC(profile.bic)} value={profile.bic} onChange={this.onChangeBic} />
                      <Form.Control.Feedback type="invalid">
                        BIC ist ungültig.
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Row>
                    <Col xs={4}></Col>
                    <Col xs={8}><Button variant="primary" type="submit" disabled={this.state.loading}>Bankverbindung ändern</Button></Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </div> : null}
      </Container>
    );
  }
}
