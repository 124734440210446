import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from './../const';
import AuthService from "./auth.service"

const API_URL_PERSON = baseUrl + 'person';
const API_URL = baseUrl + 'certificate';

class CertificateService {
    getCertificatesForPerson(fetchInfo, successCallback) {
        let id = fetchInfo;
        if (AuthService.getCurrentUser() == null) {
            return;
        }
        if (id == null || undefined) {
            id = AuthService.getCurrentUser().id;
        }
        return axios.get(API_URL_PERSON, { headers: authHeader(), params: { id } });
    }

    getAvailableCertificates() {
        return axios.get(API_URL + '/availableCertificates', { headers: authHeader() });
    }

    uploadCertificate(c) {
        let content = new FormData();
        content.append("file", c.file);
        content.append("certificate", JSON.stringify(c));
        return axios.post(API_URL, content, { headers: authHeader() });
    }

    getCertificates(verified) {
        return axios.get(API_URL, { headers: authHeader(), params: { verified } })
    }

    getDocument(pqid, mime) {
        return axios.get(API_URL + "/document", { headers: authHeader(), params: { pqid, mime } });
    }

    verify(pqid) {
        return axios.post(API_URL + "/verify", null, { headers: authHeader(), params: { pqid } });
    }

    update(c) {
        return axios.put(API_URL, c, { headers: authHeader() });
    }

    delete(pqid) {
        return axios.delete(API_URL, { headers: authHeader(), params: { pqid } });
    }

}

export default new CertificateService();