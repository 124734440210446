import React, { Component } from "react";
import { Container, Table, ButtonGroup, ToggleButton, Modal, Row, Col, Button, Form } from "react-bootstrap";
import BootstrapMenu from "bootstrap-menu";

import WachplanService from "../services/wachplan.service";
import AbrechnungService from "../services/abrechnung.service";
import TravelexpensesService from "../services/travelexpenses.service";
import Select from "react-select";
import StationService from "../services/station.service";

export default class BoardModeratorPlan extends Component {
  constructor(props) {
    super();
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.handleWachplan = this.handleWachplan.bind(this);
    this.handleFahrtkosten = this.handleFahrtkosten.bind(this);
    this.handleAnrede = this.handleAnrede.bind(this);
    this.handleProzent = this.handleProzent.bind(this);
    this.state = {
      kws: [],
      dates: [],
      plan: [],
      days: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      month: { 5: "Mai", 6: "Juni", 7: "Juli", 8: "August", 9: "September" },
      wgCount: [],
      wgSleep: [],
      status: ["", "orange", "gruen", "rot"],
      start: null,
      end: null,
      activeView: 0,
      activeKw: 0,
      showDeparture: true,
      modal: false,
      letterModal: false,
      anrede: '',
      modalData: null,
      modalPerson: null,
      fahrtkosten: 0,
      prozent: 0,
      clickPos: {x: 0, y: 0}
    };
  }

  componentDidMount() {
    StationService.getSaison().then(resp => {
      let saison = parseInt(resp.data.saison);
      let now = new Date();
      let onejan = new Date(now.getFullYear(), 0, 1);
      let week = Math.ceil((((now.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7) - 1;
      AbrechnungService.getKws().then(
        response => {
          let monday = this.getDateOfISOWeek(week, saison)
          let sunday = this.getDateOfISOWeek(week, saison);
          sunday.setDate(sunday.getDate() + 6);
          let kw = { id: week, start: (('0' + monday.getDate()).slice(-2)) + "." + (('0' + (monday.getMonth() + 1)).slice(-2)) + "." + monday.getFullYear(), end: (('0' + sunday.getDate()).slice(-2)) + "." + (('0' + (sunday.getMonth() + 1)).slice(-2)) + "." + sunday.getFullYear() }
          if (week < response.data[0].id || week > response.data[response.data.length - 1].id) {
            kw = response.data[0]
          }
          this.handleSelect({ label: "KW" + kw.id + " " + kw.start + " - " + kw.end, value: kw.id });
          this.setState({
            kws: response.data
          })
        },
        error => {
        }
      );
    });
  }

  getDateOfISOWeek(w, y) {
    var simple = new Date(y, 0, 1 + (w - 1) * 7, 2);
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;
  }

  formatDate(d) {
    d = new Date(d);
    return (('0' + d.getDate()).slice(-2)) + "." + (('0' + (d.getMonth() + 1)).slice(-2)) + "." + d.getFullYear()
  }

  handleWachplan(resp) {
    this.setState({
      wgCount: [],
      wgSleep: [],
      plan: resp
    });
  }

  handleSelect(e) {

    StationService.getSaison().then(resp => {
      let saison = parseInt(resp.data.saison);
      let start;
      let end;
      let dates;

      if (this.state.activeView === 0) {
        start = this.getDateOfISOWeek(e.value, saison);
        end = this.getDateOfISOWeek(e.value, saison);
        end.setDate(start.getDate() + 6);
        dates = this.getDaysArray(start, end);
      } else {
        start = new Date(saison, e.value - 1, 1);
        end = new Date(saison, e.value, 0);
        dates = this.getDaysArray(start, end);
      }
      this.setState({
        start: start.getFullYear() + '-' + (('0' + (start.getMonth() + 1)).slice(-2)) + '-' + (('0' + start.getDate()).slice(-2)),
        end: end.getFullYear() + '-' + (('0' + (end.getMonth() + 1)).slice(-2)) + '-' + (('0' + end.getDate()).slice(-2)),
        dates,
        activeKw: e
      });
      this.getStuff(start.getFullYear() + '-' + (('0' + (start.getMonth() + 1)).slice(-2)) + '-' + (('0' + start.getDate()).slice(-2)), end.getFullYear() + '-' + (('0' + (end.getMonth() + 1)).slice(-2)) + '-' + (('0' + end.getDate()).slice(-2)));
    });

  }

  getStuff(start, end) {
    end = new Date(end);
    end.setDate(end.getDate() + 1);
    end = end.getFullYear() + '-' + (('0' + (end.getMonth() + 1)).slice(-2)) + '-' + (('0' + end.getDate()).slice(-2))
    WachplanService.getWachplanNeu({ start, end, station: 1 }, this.handleWachplan, null);
  }

  getDaysArray(s, e) {
    let a = [];
    for (let d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    } return a;
  }

  handleClick(color, event, e, date, p) {
    this.setState({
      clickPos: {x: event.clientX, y: event.clientY}
    });
    console.log("x, ", event.clientX)
    if (color === "orange") {
      this.handleConfirmation(event, e);
    } else {
      this.handleEinteilung(event, e, p, date);
    }
  }

  handleConfirmation(event, e) {
    return new BootstrapMenu("td.orange", {
      menuEvent: "click",
      actions: [
        {
          name: e.tage + " Wachtage",
          onClick: () => { }
        },
        {
          name: "Wachanmeldung löschen",
          onClick: () => {
            if (window.confirm("Anmeldung wirklich löschen?")) {
              WachplanService.delete(e.anmeldung).then(() => {
                this.getStuff(this.state.start, this.state.end)
              });
            }
          }
        },
        {
          name: "Bestätigen, 0% Fastkosten erstatten",
          onClick: () => {
            WachplanService.confirm(e.anmeldung, 0).then(() => {
              this.getStuff(this.state.start, this.state.end)
            });
          }
        },
        {
          name: "Bestätigen, 50% Fahrtkosten erstatten",
          onClick: () => {
            WachplanService.confirm(e.anmeldung, 50).then(() => {
              this.getStuff(this.state.start, this.state.end)
            });
          }
        },
        {
          name: "Bestätigen, 100% Fahrtkosten erstatten",
          onClick: () => {
            WachplanService.confirm(e.anmeldung, 100).then(() => {
              this.getStuff(this.state.start, this.state.end)
            });
          }
        }
      ]
    });
  }

  handleEinteilung(event, e, p, date) {
    return new BootstrapMenu("td.gruen", {
      menuEvent: "click",
      actions: [
        {
          name: e.tage + " Wachtage",
          onClick: () => { }
        },
        {
          name: "Fahrkostenabrechnung",
          onClick: () => {
            this.setState({
              modal: true,
              modalData: e,
              modalPerson: p,
              prozent: this.calculateDefault(e.tage, e.anreisetag),
              fahrtkosten: 0
            })
          }
        },
        {
          name: "Bescheinigungen",
          onClick: () => {
            this.setState({
              letterModal: true,
              modalData: e,
              modalPerson: p,
              anrede: ''
            })
          }
        },
        {
          name: "Wachanmeldung löschen",
          onClick: () => {
            if (window.confirm("Anmeldung wirklich löschen?")) {
              WachplanService.delete(e.anmeldung).then(() => {
                this.getStuff(this.state.start, this.state.end)
              });
            }
          }
        },
        {
          name: "Einteilung löschen",
          onClick: () => {
            WachplanService.unassign(date, p.person.id, 1).then(() => {
              this.getStuff(this.state.start, this.state.end)
            });
          }
        },
        {
          name: "Als WF einteilen",
          onClick: () => {
            WachplanService.assign(date, p.person.id, 1).then(() => {
              this.getStuff(this.state.start, this.state.end)
            });
          }
        },
        {
          name: "Als SWF einteilen",
          onClick: () => {
            WachplanService.assign(date, p.person.id, 2).then(() => {
              this.getStuff(this.state.start, this.state.end)
            });
          }
        },
        {
          name: "Als BF einteilen",
          onClick: () => {
            WachplanService.assign(date, p.person.id, 3).then(() => {
              this.getStuff(this.state.start, this.state.end)
            });
          }
        }
      ]
    });
  }

  calculateDefault(tage, anreise) {
    let a = new Date(anreise)
    if (tage < 7) {
      return 0
    }
    if (tage >= 14) {
      return 100
    }
    if (a.getMonth() < 6 || a.getMonth() > 7) {
      return 100
    } else {
      return 50
    }

  }

  handleFahrtkosten(e) {
    this.setState({
      fahrtkosten: e.target.value
    })
  }

  handleAnrede(e) {
    this.setState({
      anrede: e.target.value
    })
  }

  handleProzent(e) {
    this.setState({
      prozent: e.target.value
    })
  }

  render() {
    let wgCount = [];
    let wgSleep = [];
    return (
      <Container fluid="true">
        <Modal show={this.state.modal} onHide={() => this.setState({ modal: false })} aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Fahrkostenabrechnung
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col xs={12} md={8}>
                  Wachgänger*in
                </Col>
                <Col xs={6} md={4}>
                  {this.state.modalPerson ? this.state.modalPerson.person.vorname + ' ' + this.state.modalPerson.person.nachname : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  Anreise
                </Col>
                <Col xs={6} md={4}>
                  {this.state.modalData ? this.formatDate(this.state.modalData.anreisetag) : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  Abreise
                </Col>
                <Col xs={6} md={4}>
                  {this.state.modalData ? this.formatDate(this.state.modalData.abreisetag) : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  Wachtage
                </Col>
                <Col xs={6} md={4}>
                  {this.state.modalData ? this.state.modalData.tage : ''}
                </Col>
              </Row>

              <Row>
                <Col xs={6} md={4}>
                  Entstandene Fahrtkosten (€)<br />
                </Col>
                <Col xs={6} md={4}>
                  Erstattung (%)<br />
                </Col>
                <Col xs={6} md={4}>
                  Erstattete Fahrtkosten<br />
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <Form.Control type="number" placeholder="Fahrtkosten" min={0.00} max={1000.00} step={0.01} value={this.state.fahrtkosten} onChange={this.handleFahrtkosten} />
                </Col>
                <Col xs={6} md={4}>
                  <Form.Control type="number" placeholder="Fahrtkosten" min={0.00} max={1000.00} step={1.00} value={this.state.prozent} onChange={this.handleProzent} />
                </Col>
                <Col xs={6} md={4}>
                  {(this.state.fahrtkosten * this.state.prozent / 100).toFixed(2)}€
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClickCapture={() => TravelexpensesService.saveTravelexpenses(this.state.modalData.anmeldung, this.state.fahrtkosten, this.state.prozent) && this.setState({ modal: false })}>Speichern</Button>
            <Button onClick={() => this.setState({ modal: false })} variant="danger">Abbrechen</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.letterModal} onHide={() => this.setState({ letterModal: false })} aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Bescheinigungen
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid">
            <Container>
              <Row>
                <Col xs={12} md={8}>
                  Wachgänger*in
                </Col>
                <Col xs={6} md={4}>
                  {this.state.modalPerson ? this.state.modalPerson.person.vorname + ' ' + this.state.modalPerson.person.nachname : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  Anreise
                </Col>
                <Col xs={6} md={4}>
                  {this.state.modalData ? this.formatDate(this.state.modalData.anreisetag) : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  Abreise
                </Col>
                <Col xs={6} md={4}>
                  {this.state.modalData ? this.formatDate(this.state.modalData.abreisetag) : ''}
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  Wachtage
                </Col>
                <Col xs={6} md={4}>
                  {this.state.modalData ? this.state.modalData.tage : ''}
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={8}>
                  Anrede<br />
                </Col>
                <Col xs={6} md={4}>
                  <Form.Control type="text" placeholder="Anrede" onChange={this.handleAnrede} value={this.state.anrede} />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClickCapture={() => WachplanService.preletter(this.state.modalData.anmeldung, this.state.anrede) }>Bescheinigung Vorher</Button>
            <Button variant="success" onClickCapture={() => WachplanService.postletter(this.state.modalData.anmeldung, this.state.anrede) }>Bescheinigung Nachher</Button>
            <Button onClick={() => this.setState({ letterModal: false })} variant="danger">Abbrechen</Button>
          </Modal.Footer>
        </Modal>
        <ButtonGroup toggle>
          <ToggleButton
            key={0}
            type="radio"
            variant="secondary"
            name="radio"
            value={"Woche"}
            checked={this.state.activeView === 0}
            onChange={(e) => this.setState({ activeView: 0 })}
          >
            {"Woche"}
          </ToggleButton>
          <ToggleButton
            key={1}
            type="radio"
            variant="secondary"
            name="radio"
            value={"Monat"}
            checked={this.state.activeView === 1}
            onChange={(e) => this.setState({ activeView: 1 })}
          >
            {"Monat"}
          </ToggleButton>
        </ButtonGroup>
        &nbsp;&nbsp;
        <ButtonGroup toggle>
          <ToggleButton
            type="checkbox"
            variant="secondary"
            checked={this.state.showDeparture}
            value="1"
            onChange={(e) => this.setState({ showDeparture: !this.state.showDeparture })}
          >
            An-/Abreise
          </ToggleButton>
        </ButtonGroup>
        <br />
        <br />
        <div style={{ width: "300px" }}>
          <Select
            options={this.state.activeView === 0 ? this.state.kws.map(kw => {
              return { label: "KW" + kw.id + " " + kw.start + " - " + kw.end, value: kw.id }
            }) : Object.entries(this.state.month).map(v => {
              return { label: v[1], value: v[0] }
            })}
            onChange={(e) => { this.handleSelect(e) }}
            value={this.state.activeKw}
          />
        </div>
        <Table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              {this.state.dates.map(d => {
                return <th>{this.state.days[d.getDay()]}</th>
              })}
            </tr>
            <tr>
              <th>Name</th>
              <th>MJ</th>
              <th>BF</th>
              {this.state.dates.map(d => {
                return <th>{d.getDate()}</th>
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.plan.map(p => {
              if (new Date(Object.entries(p.einteilung)[0][1].tag) > this.state.dates[this.state.dates.length - 1] && new Date(Object.entries(p.einteilung)[0][1].anreisetag) > this.state.dates[this.state.dates.length - 1]) {
                return null;
              }
              return (
                <tr>
                  <td>{p.person.vorname + " " + p.person.nachname}</td>
                  <td><b>{Math.abs(new Date(Date.now() - new Date(p.person.geburtsdatum)).getUTCFullYear() - 1970) < 18 ? "X" : ""}</b></td>
                  <td><b>{p.person.qualifikations.filter(q => [3, 4, 17].includes(q.qualifikation.id)).length > 0 ? "X" : ""}</b></td>
                  {this.state.dates.map(d => {
                    const curDate = d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2));
                    let e = p.einteilung[curDate];
                    let rolle = ((e || { rolle: { shortName: "" } }).rolle || { shortName: "WG" }).shortName;
                    let d2 = new Date(curDate);
                    d2.setDate(d2.getDate() + 1)
                    let curDate2 = d2.getFullYear() + '-' + (('0' + (d2.getMonth() + 1)).slice(-2)) + '-' + (('0' + d2.getDate()).slice(-2));
                    let d3 = new Date(curDate);
                    d3.setDate(d3.getDate() - 1)
                    let curDate3 = d3.getFullYear() + '-' + (('0' + (d3.getMonth() + 1)).slice(-2)) + '-' + (('0' + d3.getDate()).slice(-2));
                    let color;
                    if (rolle !== "") {
                      let cou = wgCount[curDate] || 0;
                      wgCount[curDate] = ++cou;
                      color = this.state.status[(e || { status: { id: 0 } }).status.id];
                      if (p.einteilung[curDate2] || p.einteilung[curDate].abreisetag === curDate2) {
                        let cou = wgSleep[curDate] || 0;
                        wgSleep[curDate] = ++cou;
                      }
                    }
                    if (rolle === "" && p.einteilung[curDate2] && p.einteilung[curDate2].anreisetag === curDate) {
                      if (this.state.showDeparture) {
                        rolle = "An"
                        color = this.state.status[(p.einteilung[curDate2] || { status: { id: 0 } }).status.id]
                      }
                      let cou = wgSleep[curDate] || 0;
                      wgSleep[curDate] = ++cou;
                    }
                    if (rolle === "" && p.einteilung[curDate3] && p.einteilung[curDate3].abreisetag === curDate) {
                      if (this.state.showDeparture) {
                        rolle = "Ab"
                        color = this.state.status[(p.einteilung[curDate3] || { status: { id: 0 } }).status.id]
                      }
                    }
                    return (
                      <td onClick={(event) => this.handleClick(color, event, e, curDate, p)} className={color + " " + rolle.toLowerCase()}>
                        {rolle}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td>Wachgänger</td>
              <td></td>
              <td></td>
              {this.state.dates.map(d => {
                return (
                  <td>
                    {wgCount[d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2))] || 0}
                  </td>)
              })}
            </tr>
            <tr>
              <td>Übernachtungen</td>
              <td></td>
              <td></td>
              {this.state.dates.map(d => {
                return (
                  <td>
                    {wgSleep[d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2))] || 0}
                  </td>)
              })}
            </tr>
            <tr>
              <td>Freie Plätze</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              {this.state.dates.map(d => {
                return <th>{d.getDate()}</th>
              })}
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              {this.state.dates.map(d => {
                return <th>{this.state.days[d.getDay()]}</th>
              })}
            </tr>
          </tfoot>
        </Table>
        <style type="text/css">
        {'.bootstrapMenu {left: '+this.state.clickPos.x+'px; top: '+this.state.clickPos.y+'px}'}
      </style>
      </Container>
    );
  }
}
