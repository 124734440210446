import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Button from "react-bootstrap/Button";

import AuthService from "../services/auth.service";
import { Card } from "react-bootstrap";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        Füllen Sie dieses Feld aus!
      </div>
    );
  }
};


export default class BoardResetPassword extends Component {
  constructor(props) {
    super();
    this.handlePasswordReset = this.handlePasswordReset.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);

    this.state = {
      email: "",
      loading: false,
      message: ""
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  handlePasswordReset(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      AuthService.resetPassword(this.state.email).then(
        () => {
          window.location.reload();
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          this.setState({
            loading: false,
            message: resMessage
          });
        }
      );
    } else {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    return (
      <Card style={{ maxWidth: '25rem' }}>
        <Card.Header>
          Passwort vergessen?
        </Card.Header>
        <Card.Body>
          <Form
            onSubmit={this.handlePasswordReset}
            ref={c => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="email">E-Mail-Adresse</label>
              <Input
                type="email"
                className="form-control"
                name="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
                validations={[required]}
              />
            </div>
            <br />
            <div className="form-group">
              <Button
                block
                as="input"
                type="submit"
                disabled={this.state.loading}
                value="Passwort zurücksetzen">
              </Button>
            </div>

            {this.state.message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={c => {
                this.checkBtn = c;
              }}
            />
          </Form>
        </Card.Body>
      </Card>
    );
  }
}
