import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Container, Table } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import AbrechnungService from "../services/abrechnung.service";

export default class BoardOfficeAbrechnung extends Component {
  constructor(props) {
    super();
    this.state = {
      kws: []
    };

    library.add(faDownload);
  }

  componentDidMount() {
    AbrechnungService.getKws().then(
      response => {
        this.handleKws(response.data);
      },
      error => {
      }
    );
  }

  handleKws(kws) {
    this.setState({
      kws
    })
  }

  render() {
    return (
      <Container>
        <h2>Wachabrechnung</h2>
        {this.state.kws.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Kalenderwoche</th><th>Download PDF</th><th>Download SEPA-Datei</th>
              </tr>
            </thead>
            <tbody>
              {this.state.kws.map(value => {
                return <tr><td>{value.id} ({value.start} - {value.end})</td><td><FontAwesomeIcon icon='download' onClick={() => AbrechnungService.getAbrechnungPdf(value.id)} /></td><td><FontAwesomeIcon icon='download' onClick={() => AbrechnungService.getAbrechnungSepa(value.id)} /></td></tr>
              })}
            </tbody>
          </Table>
        )}
      </Container>
    );
  }
}
