import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Container, Table, Modal, Button , Form } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStamp, faTimes, faCheck, faEnvelope, faTrash } from "@fortawesome/free-solid-svg-icons";
import CertificateService from "../services/certificate.service";
import { isMacOs, isSafari } from "react-device-detect";
import DatePicker from "react-date-picker";

export default class BoardOfficeCertificate extends Component {
  constructor(props) {
    super();
    this.handleVerified = this.handleVerified.bind(this);
    this.state = {
      verified: [],
      verifiedLoaded: false,
      unverified: [],
      unverifiedLoaded: false,
      allcerts: [],
      allcertsLoaded: false,
      modalShow: false,
      modalCert: {},
      modalDoc: null
    };

    library.add(faCheck);
    library.add(faTimes);
    library.add(faStamp);
    library.add(faEnvelope);
    library.add(faTrash);
  }

  componentDidMount() {
    CertificateService.getCertificates(true).then(
      response => {
        this.handleVerified(response.data);
      },
      error => {
      }
    );
    CertificateService.getCertificates(false).then(
      response => {
        this.handleUnverified(response.data);
      },
      error => {
      }
    );
  }

  handleVerified(certs) {
    this.setState({
      verified: certs,
      verifiedLoaded: true
    });
    if(!this.state.allcertsLoaded && this.state.unverifiedLoaded) {
      this.setState({
        allcertsLoaded: true,
        allcerts: this.state.unverified.concat(certs)
      })
    }
  }

  handleUnverified(certs) {
    this.setState({
      unverified: certs,
      unverifiedLoaded: true
    });
    if(!this.stateallcertsLoaded && this.state.verifiedLoaded) {
      this.setState({
        allcertsLoaded: true,
        allcerts: this.state.unverified.concat(certs)
      })
    }
  }

  verify(key) {
    let certs = this.state.allcerts;
    certs[key].verified = true;
    CertificateService.verify(certs[key].id);
    this.setState({
      allcerts: certs
    });
  }

  delete(key) {
    if(window.confirm("Soll die Qualifikation wirklich gelöscht werden?")) {
      let certs = this.state.allcerts;
      CertificateService.delete(certs[key].id);
      certs.splice(key,1);
      this.setState({
        allcerts: certs
      });
    }
  }

  formatDate(d) {
    d = new Date(d);
    if (d.getFullYear() === 1970) {
      return "unbegrenzt"
    }
    return (('0' + d.getDate()).slice(-2)) + "." + (('0' + (d.getMonth() + 1)).slice(-2)) + "." + d.getFullYear()
  }

  showModal(cert) {
    CertificateService.getDocument(cert.id, cert.mime).then((resp) => {
      let doc;
      if(cert.mime === 'pdf') {
        doc = <iframe title="dokument" width="700" height="700" src={'data:application/pdf;base64,' + resp.data}></iframe>;
      } else {
        doc = <img alt="" width="700" src={'data:image/' + cert.mime + ';base64,' + resp.data} />;
      }
      this.setState({
        modalDoc: doc,
        modalCert: cert,
        modalShow: true
      })
    })
  }

  getCalendar(cert, key) {
    if (isMacOs && isSafari) {
      return <DatePicker
        maxDate={new Date()}
        value={new Date(cert.erwerb)}
        format="dd.MM.y"
        onChange={(date) => this.updateCertificate(key, date.target.value)}
      />
    } else {
      return <Form.Control type="date" value={cert.erwerb.split("T")[0]} onChange={(date) => this.updateCertificate(key, date.target.value)} />
    }
  }

  updateCertificate(key, date) {
    let certs = this.state.allcerts;
    certs[key].erwerb = date;
    this.setState({
      allcerts: certs
    });
    CertificateService.update(certs[key]);
  }

  getMailToLink(cert) {
    return "mailto:" + cert.person.email + "?subject=Qualifikationsnachweis " + cert.qualifikation.name + "&body=Hallo " + cert.person.vorname + " " + cert.person.nachname + ","
  }

  render() {
    return (
      <Container>
        <h2>Hochgeladene Qualifikationen</h2>
       <Modal
        show={this.state.modalShow}
        onHide={() => this.setState({modalShow: false})}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.state.modalCert.qualifikation ? this.state.modalCert.person.nachname + ", " + this.state.modalCert.person.vorname + ": " + this.state.modalCert.qualifikation.name + ", " + this.formatDate(this.state.modalCert.erwerb) + " - " + this.formatDate(this.state.modalCert.ablauf) : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.modalDoc}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.setState({modalShow: false})}>Close</Button>
        </Modal.Footer>
      </Modal>
        { this.state.allcerts.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Wachgänger</th><th>Schein/Qualifikation</th><th>bestätigt</th><th>Ausgestellt</th><th>Gültig bis</th><th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.allcerts.map((cert, key) => {
                return <tr><td>{cert.person.nachname}, {cert.person.vorname}</td><td>{cert.qualifikation.name} {cert.mime && (<span><FontAwesomeIcon icon='stamp' onClick={() => this.showModal(cert)} />&nbsp;<a href={this.getMailToLink(cert)}><FontAwesomeIcon icon="envelope" /></a></span>)}</td><td>{cert.verified ? <FontAwesomeIcon icon='check' /> : <FontAwesomeIcon icon='times' onClick={() => this.verify(key)} />}</td><td>{this.getCalendar(cert, key)}</td><td>{this.formatDate(cert.ablauf)}</td><td><FontAwesomeIcon icon='trash' onClick={() => this.delete(key)} /></td></tr>
              })}
            </tbody>
          </Table>
        )}
      </Container>
    );
  }
}
