import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from './../const';
import fileDownload from 'js-file-download';

const API_URL = baseUrl + 'wachplan/';

class WachplanService {
    getWachgaenger(fetchInfo, successCallback, failureCallback) {
        axios.get(API_URL + 'wachgaenger', {
            headers: authHeader(),
            params: {
                start: fetchInfo.startStr,
                end: fetchInfo.endStr,
                station: 1
            }
        }).then((resp) => {
            successCallback(resp.data);
        });
    }

    getWachplan(fetchInfo, successCallback, failureCallback) {
        axios.get(API_URL + 'wachanmeldungen', {
            headers: authHeader(),
            params: {
                start: fetchInfo.startStr,
                end: fetchInfo.endStr,
                station: 1
            }
        }).then((resp) => {
            successCallback(resp.data);
        });
    }

    getWachplanNeu(fetchInfo, successCallback, failureCallback) {
        axios.get(API_URL + 'neu', {
            headers: authHeader(),
            params: {
                start: fetchInfo.start,
                end: fetchInfo.end,
                station: fetchInfo.station
            }
        }).then((resp) => {
            successCallback(resp.data);
        });
    }

    getBelegungszahlen(station) {
        return axios.get(API_URL + 'belegungszahlen', { headers: authHeader(), params: { station } });
    }

    confirm(id, erstattung) {
        return axios.post(API_URL + 'confirm?id=' + id + '&erstattung=' + erstattung, null, { headers: authHeader() });
    }

    delete(id) {
        return axios.delete(API_URL + 'delete?id=' + id, { headers: authHeader() });
    }

    assign(date, person, role) {
        return axios.post(API_URL + 'assign?date=' + date + '&person=' + person + '&role=' + role, null, { headers: authHeader() });
    }

    unassign(date, person) {
        return axios.delete(API_URL + 'unassign?date=' + date + '&person=' + person, { headers: authHeader() });
    }

    preletter(id, artikel) {
        return axios.get(API_URL + 'preletter?id=' + id + '&artikel=' + artikel, { headers: authHeader(), responseType: 'blob' }, ).then((resp) => {
            fileDownload(resp.data, "Bescheinigung_Anmeldung_" + id + ".pdf");
        });
    }

    postletter(id, artikel) {
        return axios.get(API_URL + 'postletter?id=' + id + '&artikel=' + artikel, { headers: authHeader(), responseType: 'blob' }, ).then((resp) => {
            fileDownload(resp.data, "Bescheinigung_Wachdienst_" + id + ".pdf");
        });    }

}

export default new WachplanService();