import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from './../const';
import fileDownload from 'js-file-download';

const API_URL = baseUrl + 'wachschild/';

class AusweisService {
    getWachgaenger(fetchInfo, successCallback, failureCallback) {
        return axios.get(API_URL + 'wachgaenger', {
            headers: authHeader(),
        });
    }

    getWachschild(id, name) {
        return axios.get(API_URL, {
            headers: authHeader(),
            params: {
                personId: id
            },
            responseType: 'blob', // Important
        }).then((resp) => {
            fileDownload(resp.data, name + ".pdf");
        });
    }

}

export default new AusweisService();