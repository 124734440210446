import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from '../const';
import fileDownload from 'js-file-download';

const API_URL = baseUrl + 'travelexpenses/';

class TravelexpensesService {

    getAbrechnungPdf(date) {
        return axios.get(API_URL, {
            headers: authHeader(),
            params: {
                date
            },
            responseType: 'blob',
        }).then((resp) => {
            fileDownload(resp.data, "Abrechnung_" + date + ".pdf");
        });
    }

    getAbrechnungSepa(date) {
        return axios.get(API_URL + 'sepa', {
            headers: authHeader(),
            params: {
                date
            },
            responseType: 'blob',
        }).then((resp) => {
            fileDownload(resp.data, "Abrechnung_" + date + ".xml");
        });
    }

    getTravelexpenses() {
        return axios.get(API_URL + 'dates', {
            headers: authHeader(),
        });
    }

    saveTravelexpenses(aid, expenses, percent) {
        return axios.post(API_URL, { aid, fahrtkosten: expenses, prozentsatz: percent }, { headers: authHeader() });
    }

}

export default new TravelexpensesService();