import React, { Component } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import BoardLogin from "./components/board-login.component";
import BoardRegister from "./components/board-register.component";
import BoardResetPassword from "./components/board-resetpassword.component";
import BoardHome from "./components/board-home.component";
import BoardProfile from "./components/board-profile.component";
import BoardModerator from "./components/board-moderator.component";
import BoardAccount from "./components/board-account.component";
import BoardCertificates from "./components/board-certificates.component";
import BoardApply from "./components/board-apply.component";
import BoardOffice from "./components/board-office.component";
import BoardOfficeAusweis from "./components/board-office-ausweis.component";
import BoardLeader from "./components/board-leader.component";
import BoardModeratorPlan from "./components/board-moderator-plan.component";
import BoardOfficeCertificate from "./components/board-office-certificate.component";
import BoardOfficeAbrechnung from "./components/board-office-abrechnung.component";
import BoardTravelexpensesComponent from "./components/board-travelexpenses";
import BoardModeratorEinweisungen from "./components/board-einweisungen.component";

class App extends Component {
  constructor(props) {
    super();
    this.logOut = this.logOut.bind(this);

    this.state = {
      showOffice: false,
      showLeader: false,
      showModerator: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      this.setState({
        currentUser: user,
        showOffice: user.roles.includes("OFFICE") || user.roles.includes("MODERATOR"),
        showLeader: user.roles.includes("LEADER"),
        showModerator: user.roles.includes("MODERATOR"),
      });
    }
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    const { currentUser, showOffice, showLeader, showModerator } = this.state;

    return (
      <div>
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Container fluid>
            <LinkContainer to="/home">
              <Navbar.Brand><img src={process.env.PUBLIC_URL + '/dlrgluebeck.svg'} width="250" alt="" /></Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <LinkContainer to="/home">
                  <Nav.Link>Start</Nav.Link>
                </LinkContainer>
                {currentUser && !showLeader && (
                  <LinkContainer to="/apply">
                    <Nav.Link>Wachanmeldung</Nav.Link>
                  </LinkContainer>
                )}
                {currentUser && !showLeader && (
                  <LinkContainer to="/profile">
                    <Nav.Link>Persönliche Angaben</Nav.Link>
                  </LinkContainer>
                )}
                {currentUser && !showLeader && (
                  <LinkContainer to="/certificates">
                    <Nav.Link>Qualifikationen</Nav.Link>
                  </LinkContainer>
                )}
                {showLeader && (
                  <LinkContainer to="/leader">
                    <Nav.Link>Wachführer</Nav.Link>
                  </LinkContainer>
                )}
                {showOffice && (
                  <NavDropdown title="Verwaltung" id="collasible-nav-dropdown">
                    <LinkContainer to="/office/certificates">
                      <NavDropdown.Item>Qualifikationen</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/office/ausweis">
                      <NavDropdown.Item>Wach- und Fährausweise</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/office/abrechnung">
                      <NavDropdown.Item>Abrechnung</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/office/travelexpenses">
                    <NavDropdown.Item>Fahrtkosten</NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                )}
                {showModerator && (
                  <NavDropdown title="Ressort WRD" id="collasible-nav-dropdown">
                    <LinkContainer to="/moderator/plan">
                      <NavDropdown.Item>Wachplan</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item>Wachgänger</NavDropdown.Item>
                    <LinkContainer to="/moderator/einweisungen">
                      <NavDropdown.Item>Einweisungen</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>Vorlagen</NavDropdown.Item>
                    <NavDropdown.Item>Station & Saison</NavDropdown.Item>
                  </NavDropdown>
                )}
              </Nav>
              {currentUser ? (
                <Nav>
                  {/* <LinkContainer to="/profile">
                  <Nav.Link>Mein Konto</Nav.Link>
                </LinkContainer> */}
                  <Nav.Link href="/login" onClick={this.logOut}>Abmelden</Nav.Link>
                </Nav>
              ) : (
                <Nav>
                  <LinkContainer to="/login">
                    <Nav.Link>Anmelden</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/register">
                    <Nav.Link>Registrieren</Nav.Link>
                  </LinkContainer>
                </Nav>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container fluid className={["mt-3"]}>
          <Routes>
          <Route exact path={"/"} element={<BoardHome /> } />
          <Route exact path={"/home"} element={<BoardHome />} />
            <Route path="/apply" element={<BoardApply />} />
            <Route path="/profile" element={<BoardProfile />} />
            <Route path="/certificates" element={<BoardCertificates />} />
            <Route exact path="/leader" element={<BoardLeader />} />
            <Route exact path="/office" element={<BoardOffice />} />
            <Route exact path="/office/ausweis" element={<BoardOfficeAusweis />} />
            <Route exact path="/office/abrechnung" element={<BoardOfficeAbrechnung />} />
            <Route exact path="/office/certificates" element={<BoardOfficeCertificate />} />
            <Route exact path="/office/travelexpenses" element={<BoardTravelexpensesComponent />} />
            <Route exact path="/moderator" element={<BoardModerator />} />
            <Route exact path="/login" element={<BoardLogin />} />
            <Route exact path="/resetpassword" element={<BoardResetPassword />} />
            <Route exact path="/account" element={<BoardAccount />} />
            <Route exact path="/register" element={<BoardRegister />} />
            <Route exact path="/moderator/plan" element={<BoardModeratorPlan />} />
            <Route exact path="/moderator/einweisungen" element={<BoardModeratorEinweisungen />} />
          </Routes>
        </Container>
      </div >
    );
  }
}

export default App;
