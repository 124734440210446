import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Button, Card, Container, Table, Form, Col, FormLabel, FormGroup, Row } from "react-bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import PersonService from "../services/person-service";
import EinweisungService from "../services/einweisung.service";
import AuthService from "../services/auth.service";
import Select from "react-select";

export default class BoardModeratorEinweisungen extends Component {
  constructor(props) {
    super();
    this.onPersonSelect = this.onPersonSelect.bind(this);
    this.onMaterialSelect = this.onMaterialSelect.bind(this);
    this.onSaveEinweisung = this.onSaveEinweisung.bind(this);
    this.state = {
      einweisungen: [],
      selectedPerson: null,
      selectedMaterial: null,
      availableMaterial: []
    };

    library.add(faCheck);
  }

  componentDidMount() {
    PersonService.getAllPerson().then(
      response => {
        this.handleEinweisungen(response.data);
      },
      error => {
      }
    );
    EinweisungService.getAvailableMaterial().then((resp) => {
      this.setState({
        availableMaterial: resp.data
      });
    });

  }

  handleEinweisungen(einweisungen) {
    this.setState({
      einweisungen
    })
  }

  getPersonSelect() {
    let persons = [];
    PersonService.getAllPerson().then((resp) => {
      resp.data.map((person, key) => {
        persons.push({ value: person.id, label: person.nachname + ", " + person.vorname });
        return null;
      });
    });
    return (<Select options={persons} onChange={this.onPersonSelect} />);
  }

  getMaterialSelect() {
    let material = [];
    EinweisungService.getAvailableMaterial().then((resp) => {
      resp.data.map((m, key) => {
        material.push({ value: m.id, label: m.name });
        return null;
      });
    });
    return (<Select options={material} onChange={this.onMaterialSelect} />);
  }

  onPersonSelect(p) {
    this.setState({
      selectedPerson: p.value
    })
  }

  onMaterialSelect(m) {
    this.setState({
      selectedMaterial: m.value
    })
  }

  onSaveEinweisung() {
    EinweisungService.postEinweisung(this.state.selectedPerson, this.state.selectedMaterial).then((resp) => {
    });
  }

  render() {
    return (
      <Container>
        <h2>Einweisungen</h2>
        <Card>
          <Card.Header>
            <Card.Title>
              Neue Einweisung
            </Card.Title>
          </Card.Header>
          {AuthService.getCurrentUser().roles.includes("MODERATOR") && (

            <Card.Body>
              <Row>
                <Col><FormLabel>Wachgänger auswählen:</FormLabel></Col>
                <Col><FormLabel>Einweisung für:</FormLabel></Col>
              </Row>
              <Row>
                <Col>{this.getPersonSelect()}</Col><Col>{this.getMaterialSelect()}</Col>
              </Row>
              <Row>
                <Col>
                  <Button xs={4} variant="primary" disabled={this.state.selectedMaterial === null || this.state.selectedPerson === null} onClick={this.onSaveEinweisung}>Speichern</Button>
                </Col>
              </Row>

            </Card.Body>
          )}
        </Card>

        <h4>Einweisungsübersicht</h4>
        {this.state.einweisungen.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>Name</th>{this.state.availableMaterial.map(m => { return <th>{m.name}</th> })}
              </tr>
            </thead>
            <tbody>
              {this.state.einweisungen.map(value => {
                if (value.einweisungen.length > 0) {
                  let einweisungen = value.einweisungen.map(e => {
                    return e.material.name
                  });
                  return <tr><td>{value.nachname + ', ' + value.vorname}</td>{this.state.availableMaterial.map(m => { return <td>{einweisungen.includes(m.name) ? <FontAwesomeIcon icon='check' /> : ''}&nbsp;</td> })}</tr>

                }
              })}
            </tbody>
          </Table>
        )}
      </Container>
    );
  }
}
