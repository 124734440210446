import React, { Component } from "react";
import Table from 'react-bootstrap/Table'
import Card from 'react-bootstrap/Card';
import { Alert, Button, ToggleButtonGroup, ToggleButton, Col, Container, Form, FormGroup, FormLabel, Accordion, Row } from "react-bootstrap";
import WachanmeldungService from "../services/wachanmeldung.service"
import wachplanService from "../services/wachplan.service";
import { isMacOs, isSafari } from "react-device-detect";
import DatePicker from "react-date-picker";
import wachanmeldungService from "../services/wachanmeldung.service";
import AuthService from "../services/auth.service";
import PersonService from "../services/person-service";
import Select from "react-select";
import StationService from "../services/station.service";

export default class BoardApply extends Component {
  constructor(props) {
    super();
    this.handleBelegungszahlen = this.handleBelegungszahlen.bind(this);
    this.handleWachanmeldung = this.handleWachanmeldung.bind(this);
    this.onChangeBemerkung = this.onChangeBemerkung.bind(this);
    this.onChangeErsterWachtag = this.onChangeErsterWachtag.bind(this);
    this.onChangeLetzterWachtag = this.onChangeLetzterWachtag.bind(this);
    this.handleAnreiseChange = this.handleAnreiseChange.bind(this);
    this.handleAbreiseChange = this.handleAbreiseChange.bind(this);
    this.onPersonSelect = this.onPersonSelect.bind(this);

    this.state = {
      loading: false,
      ersterWachtagm0: "",
      ersterWachtagm1: "",
      letzterWachtagp0: "",
      letzterWachtagp1: "",
      anmeldung: {
        station: { id: 1 },
        ersterWachtag: "",
        letzterWachtag: "",
        anreisetag: "",
        abreisetag: "",
        bemerkung: ""
      },
      sameDayArrival: false,
      sameDayDeparture: false,
      message: "",
      belegungszahlen: {},
      wachtage: [],
      monate: [],
      days: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      month: { 5: "Mai", 6: "Jun", 7: "Jul", 8: "Aug", 9: "Sep" },
      saison: 0
    };

    this.baseState = JSON.parse(JSON.stringify(this.state));

  }

  onChangeErsterWachtag(e) {
    let anmeldung = this.state.anmeldung;
    anmeldung.ersterWachtag = e.target.value;
    anmeldung.anreisetag = e.target.value;

    var d = new Date(e.target.value);
    var d2 = new Date(e.target.value);
    var d3 = new Date(e.target.value);
    d2.setDate(d2.getDate() - 1)
    d3.setDate(d3.getDate() + 1)

    this.setState({
      ersterWachtagm1: (('0' + d2.getDate()).slice(-2)) + "." + (('0' + (d2.getMonth() + 1)).slice(-2)) + "." + d2.getFullYear(),
      ersterWachtagm0: (('0' + d.getDate()).slice(-2)) + "." + (('0' + (d.getMonth() + 1)).slice(-2)) + "." + d.getFullYear(),
      sameDayArrival: true
    });
    if (anmeldung.letzterWachtag == null || anmeldung.letzterWachtag == '' || new Date(anmeldung.letzterWachtag) < new Date(anmeldung.ersterWachtag)) {
      anmeldung.letzterWachtag = e.target.value;
      anmeldung.abreisetag = e.target.value;
      this.setState({
        letzterWachtagp0: (('0' + d.getDate()).slice(-2)) + "." + (('0' + (d.getMonth() + 1)).slice(-2)) + "." + d.getFullYear(),
        letzterWachtagp1: (('0' + d3.getDate()).slice(-2)) + "." + (('0' + (d3.getMonth() + 1)).slice(-2)) + "." + d3.getFullYear(),
        sameDayDeparture: true
      })
    }
  }

  getDaysArray(s, e) {
    let a = [];
    for (let d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    } return a;
  }

  onChangeLetzterWachtag(e) {
    let anmeldung = this.state.anmeldung;
    anmeldung.letzterWachtag = e.target.value;
    anmeldung.abreisetag = e.target.value;
    var d = new Date(e.target.value);
    var d2 = new Date(e.target.value);
    d2.setDate(d2.getDate() + 1)

    this.setState({
      anmeldung,
      letzterWachtagp0: (('0' + d.getDate()).slice(-2)) + "." + (('0' + (d.getMonth() + 1)).slice(-2)) + "." + d.getFullYear(),
      letzterWachtagp1: (('0' + d2.getDate()).slice(-2)) + "." + (('0' + (d2.getMonth() + 1)).slice(-2)) + "." + d2.getFullYear(),
      sameDayDeparture: true
    });
  }

  onChangeBemerkung(e) {
    let anmeldung = this.state.anmeldung;
    anmeldung.bemerkung = e.target.value
    this.setState({
      anmeldung
    })
  }

  handleWachanmeldung(e) {
    e.preventDefault();

    this.setState({
      loading: true
    });

    WachanmeldungService.apply(this.state.anmeldung).then(
      () => {
        this.setState(this.baseState);
        let message = "Deine Wachanmeldung wurde gespeichert und wird jetzt bearbeitet!"
        this.setState({
          message,
          loading: false,
        });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        wachplanService.getBelegungszahlen(1, this.handleBelegungszahlen);
      });
  }

  handleAnreiseChange(e) {
    let anmeldung = this.state.anmeldung;
    e = parseInt(e);
    let d = new Date(this.state.anmeldung.ersterWachtag);
    let arrival = e === 0 ? true : false;
    d.setDate(d.getDate() + e);
    anmeldung.anreisetag = d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2));

    this.setState({
      anmeldung,
      sameDayArrival: arrival
    });
  }

  handleAbreiseChange(e) {
    let anmeldung = this.state.anmeldung;
    e = parseInt(e);
    var d = new Date(this.state.anmeldung.letzterWachtag);
    let departure = e === 0 ? true : false;
    d.setDate(d.getDate() + e)
    anmeldung.abreisetag = d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2));

    this.setState({
      anmeldung,
      sameDayDeparture: departure
    });
  }

  formatDate(d) {
    d = new Date(d);
    return (('0' + d.getDate()).slice(-2)) + "." + (('0' + (d.getMonth() + 1)).slice(-2)) + "." + d.getFullYear()
  }

  componentDidMount() {
    StationService.getSaison().then(resp => {
      let saison = parseInt(resp.data.saison);
      let monate = [{ start: saison + "-05-15", end: saison + "-05-31" },
      { start: saison + "-06-01", end: saison + "-06-30" },
      { start: saison + "-07-01", end: saison + "-07-31" },
      { start: saison + "-08-01", end: saison + "-08-31" },
      { start: saison + "-09-01", end: saison + "-09-14" }];
      this.setState({
        monate, saison
      })
    });
    wachplanService.getBelegungszahlen(1).then((resp) => {
      const belegungszahlen = resp.data;
      wachanmeldungService.getWachzeiten().then((resp2) => {
        const wachtage = resp2.data;
        wachtage.map((anmeldung, key) => {
          belegungszahlen[anmeldung.anreisetag] = "anreisetag";
          belegungszahlen[anmeldung.abreisetag] = "abreisetag";
          const tage = this.getDaysArray(anmeldung.ersterWachtag, new Date(anmeldung.letzterWachtag));
          tage.map((d, key) => {
            belegungszahlen[d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2))] = "wachtag";
            return null;
          });
          return null;
        });
        this.setState({
          belegungszahlen,
          wachtage
        });
        return null;
      });
      return null;
    });
  }

  handleBelegungszahlen(zahlen) {
    this.setState({ belegungszahlen: zahlen })
  }

  getPersonSelect() {
    let persons = [];
    PersonService.getAllPerson().then((resp) => {
      resp.data.map((person, key) => {
        persons.push({ value: person.id, label: person.nachname + ", " + person.vorname });
        return null;
      });
    });
    return (<Select options={persons} onChange={this.onPersonSelect} />);
  }

  onPersonSelect(p) {
    let a = this.state.anmeldung;
    a.person = { id: p.value };
  }

  render() {
    return (
      <Container>
        <h3>
          Wachanmeldung
        </h3>
        {this.state.message && (
          <Alert variant={'success'}>{this.state.message}</Alert>
        )}
        <center>
          <Table className="calendar" xs={12} size="sm">
            <tbody><tr className="yeardays">
              <th className="year">{this.state.saison}</th>
              <th className="day">01</th>
              <th className="day">02</th>
              <th className="day">03</th>
              <th className="day">04</th>
              <th className="day">05</th>
              <th className="day">06</th>
              <th className="day">07</th>
              <th className="day">08</th>
              <th className="day">09</th>
              <th className="day">10</th>
              <th className="day">11</th>
              <th className="day">12</th>
              <th className="day">13</th>
              <th className="day">14</th>
              <th className="day">15</th>
              <th className="day">16</th>
              <th className="day">17</th>
              <th className="day">18</th>
              <th className="day">19</th>
              <th className="day">20</th>
              <th className="day">21</th>
              <th className="day">22</th>
              <th className="day">23</th>
              <th className="day">24</th>
              <th className="day">25</th>
              <th className="day">26</th>
              <th className="day">27</th>
              <th className="day">28</th>
              <th className="day">29</th>
              <th className="day">30</th>
              <th className="day">31</th>
            </tr>
              {
                this.state.monate.map((monat, key) => {
                  let start = new Date(monat.start);
                  let end = new Date(monat.end);
                  let arr = this.getDaysArray(start, end);
                  return (
                    <tr>
                      <td className="month">{this.state.month[start.getMonth() + 1]}</td>
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {(start.getMonth() === 4) && (
                        <td></td>
                      )}
                      {arr.map((d) => {
                        return <td className={this.state.belegungszahlen[d.getFullYear() + "-" + (('0' + (d.getMonth() + 1)).slice(-2)) + "-" + (('0' + d.getDate()).slice(-2))]}>{this.state.days[d.getDay()]}</td>
                      })}
                    </tr>)
                })
              }
            </tbody></Table>
        </center>
        <Card>
          <Card.Header>Legende</Card.Header>
          <Card.Body>
            <Table>
              <tbody>
                <tr>
                  <td>
                    <Row><div className="colorbox gruen"></div>Viele freie Plätze vorhanden</Row>
                    <Row><div className="colorbox orange"></div>Wenige freie Plätze vorhanden</Row>
                    <Row><div className="colorbox rot"></div>Keine freien Plätze vorhanden</Row>
                  </td>
                  <td>
                    <Row><div className="colorbox anreise"></div>Anreisetag</Row>
                    <Row><div className="colorbox wachtag"></div>Wachtag</Row>
                    <Row><div className="colorbox abreise"></div>Abreisetag</Row>
                  </td>
                </tr>
              </tbody>
            </Table>
            <b>Dein Wunschtermin ist schon ausgebucht?</b> Dann sprich uns bitte an und schreib uns eine E-Mail an <a href="mailto:wrd@luebeck.dlrg.de">wrd@luebeck.dlrg.de</a>. Wir finden dann bestimmt gemeinsam eine Lösung!
          </Card.Body>
        </Card>
        <h4>Anmeldung für den Wasserrettungsdienst in <b>Travemünde</b></h4>
        <Accordion defaultActiveKey="1">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <b>Deine Wachanmeldungen</b>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Table>
                  <tbody>
                    <tr><th>Station</th><th>Anreise</th><th>Erster Wachtag</th><th>Letzter Wachtag</th><th>Abreise</th><th>Status</th></tr>
                    {this.state.wachtage && (
                      this.state.wachtage.map((anmeldung, key) => {
                        return <tr><td>{anmeldung.station.name}</td><td>{this.formatDate(anmeldung.anreisetag)}</td><td>{this.formatDate(anmeldung.ersterWachtag)}</td><td>{this.formatDate(anmeldung.letzterWachtag)}</td><td>{this.formatDate(anmeldung.abreisetag)}</td><td>{anmeldung.status.name}</td></tr>
                      })
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <b>Neue Wachanmeldung</b>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <Form
                  onSubmit={this.handleWachanmeldung}
                  ref={c => {
                    this.form = c;
                  }}
                >
                  <FormGroup>
                    {AuthService.getCurrentUser().roles.includes("MODERATOR") && (
                      <Form.Row>
                        <Col><FormLabel>Wachgänger auswählen</FormLabel></Col>
                        <Col>{this.getPersonSelect()}</Col>
                      </Form.Row>
                    )}
                    <Form.Row>
                      <Col><FormLabel>Erster Wachtag</FormLabel></Col>
                      <Col>
                        {(isMacOs && isSafari) ? (
                          <DatePicker
                            minDate={new Date(this.state.saison + "-05-15")}
                            maxDate={new Date(this.state.saison + "-09-14")}
                            onChange={(d) => this.onChangeErsterWachtag({ target: { value: d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2)) } })}
                            required={true}
                            value={this.state.anmeldung.ersterWachtag ? new Date(this.state.anmeldung.ersterWachtag) : null}
                            format="dd.MM.y"
                          />
                        ) : (
                          <Form.Control type="date" min={this.state.saison + "-05-15"} max={this.state.saison + "-09-14"} required={true} value={this.state.anmeldung.ersterWachtag} onChange={this.onChangeErsterWachtag} />
                        )}
                      </Col>
                    </Form.Row>
                  </FormGroup>
                  <FormGroup>
                    <Form.Row>
                      <Col><FormLabel>Anreisetag</FormLabel></Col>
                      <Col><ToggleButtonGroup name="anreise" onChange={this.handleAnreiseChange} defaultValue={[0]}>
                        <ToggleButton variant="secondary" value={-1}>{this.state.ersterWachtagm1}&nbsp;</ToggleButton>
                        <ToggleButton variant="secondary" value={0}>{this.state.ersterWachtagm0}</ToggleButton>
                      </ToggleButtonGroup></Col>
                    </Form.Row>
                    <Form.Row hidden={!this.state.sameDayArrival} ><Col>&nbsp;</Col><Col>Der erste Wachtag beginnt um 09:30 Uhr. Solltest Du eine längere Anreise haben, so solltest Du eher am Tag vorher anreisen.</Col></Form.Row>
                  </FormGroup>
                  <FormGroup>
                    <Form.Row>
                      <Col><FormLabel>
                        Letzter Wachtag
                      </FormLabel></Col>
                      <Col>
                        {(isMacOs && isSafari) ? (
                          <DatePicker
                            minDate={new Date(this.state.anmeldung.ersterWachtag || this.state.saison + "-05-15")}
                            maxDate={new Date(this.state.saison + "-09-14")}
                            onChange={(d) => this.onChangeLetzterWachtag({ target: { value: d.getFullYear() + '-' + (('0' + (d.getMonth() + 1)).slice(-2)) + '-' + (('0' + d.getDate()).slice(-2)) } })}
                            required={true}
                            value={this.state.anmeldung.letzterWachtag ? new Date(this.state.anmeldung.letzterWachtag) : null}
                            format="dd.MM.y"
                          />
                        ) : (
                          <Form.Control type="date" min={this.state.anmeldung.ersterWachtag || this.state.saison + "-05-15"} max={this.state.saison + "-09-14"} required={true} value={this.state.anmeldung.letzterWachtag} onChange={this.onChangeLetzterWachtag} />
                        )}
                      </Col>
                    </Form.Row>
                  </FormGroup>
                  <FormGroup>
                    <Form.Row>
                      <Col><FormLabel>
                        Abreisetag
                      </FormLabel></Col>
                      <Col><ToggleButtonGroup name="abreise" onChange={this.handleAbreiseChange} defaultValue={[0]}>
                        <ToggleButton variant="secondary" value={0}>{this.state.letzterWachtagp0}&nbsp;</ToggleButton>
                        <ToggleButton variant="secondary" value={1}>{this.state.letzterWachtagp1}</ToggleButton>
                      </ToggleButtonGroup></Col>
                    </Form.Row>
                    <Form.Row hidden={!this.state.sameDayDeparture} ><Col>&nbsp;</Col><Col>Der letzte Wachtag endet um 18:00 Uhr. Solltest Du eine längere Heimreise haben, so solltest Du am nächsten Tag abreisen.</Col></Form.Row>
                  </FormGroup>
                  <FormGroup>
                    <Form.Row>
                      <Col><FormLabel>
                        Bemerkung
                      </FormLabel></Col>
                      <Col><Form.Control as="textarea" rows={5} value={this.state.anmeldung.bemerkung} onChange={this.onChangeBemerkung} /></Col>
                    </Form.Row>
                  </FormGroup>
                  <br />
                  <Button variant="primary" type="submit" disabled={this.state.loading}>Wachanmeldung absenden</Button>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container >
    );
  }
}
