import axios from 'axios';
import authHeader from './auth-header';
import { baseUrl } from './../const';

const API_URL = baseUrl + 'einweisung';

class EinweisungService {

    getAvailableMaterial() {
        return axios.get(API_URL + '/availableMaterial', { headers: authHeader() });
    }

    postEinweisung(p, m) {
        axios.post(API_URL, { person: { id: p }, material: { id: m } }, { headers: authHeader() })
    }

    deleteEinweisung() {

    }

}

export default new EinweisungService();